<template>
  <transition
    enter-active-class="animated animate__fadeIn"
    leave-active-class="animated animate__fadeOut"
    mode="out-in"
    :duration="140"
  >
    <div
      v-if="locationLoading || !locations"
      class="flex full-height items-center justify-center"
      style="min-height: inherit"
    >
      <q-spinner color="primary" size="3em" :thickness="2" />
    </div>
    <div class="q-pa-sm" v-else>
      <q-card
        class="q-mt-sm"
        v-ripple
        @click="onSelectLocation(location.signature)"
        v-for="(location, $i) in locations"
        :key="$i"
      >
        <q-card-section>
          <div class="row items-center no-wrap">
            <div class="col">
              <div class="text-h6">{{ location.signature }}</div>
              <div class="text-subtitle1 text-blue-grey-5">
                {{ location.name }}
              </div>
            </div>

            <div class="col-auto">
              <q-btn color="grey-7" round flat icon="mdi-chevron-right" />
            </div>
          </div>
        </q-card-section>
      </q-card>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useLocation } from '@/compositions/useLocation'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'Register',

  setup() {
    const router = useRouter()
    const { fetchLocations, locationLoading, locations } = useLocation()

    fetchLocations()

    function onSelectLocation(signature: string) {
      router.push(`/register/${signature}/arrivals`)
    }
    return {
      locationLoading,
      onSelectLocation,
      locations,
    }
  },
})
</script>
