import { instance } from '@/services/iss-nattag-api.service'
import { Arrival } from '@/types/arrival'
import { AxiosResponse } from 'axios'

interface GetArrivalParams {
  location: string
  trafficDates: string[]
}

const URL = 'arrival'

export const getArrivals = (
  params: GetArrivalParams
): Promise<AxiosResponse<Arrival[]>> =>
  instance.get(URL, {
    params,
  })
