
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import { defineComponent, onMounted, PropType, ref, computed } from 'vue'
import VehicleDeviationCard from './VehicleDeviationCard.vue'
import orderBy from 'lodash.orderby'
import { Deviation } from '@/types/deviation'

export default defineComponent({
  name: 'VehicleDeviationScrollList',

  components: {
    VehicleDeviationCard,
  },

  props: {
    vehicleUuid: String,
    deviations: Array as PropType<Deviation[]>,
  },

  setup(props) {
    const deviationScrollRef = ref<QScrollArea | null>(null)

    onMounted(() => {
      setTimeout(() => {
        if (!deviationScrollRef.value) return

        const box = deviationScrollRef.value.$el.getBoundingClientRect()
        deviationScrollRef.value.$el.style.height = `${
          window.innerHeight - box.top
        }px`
      }, 0)
    })

    const sortedDeviations = computed(() => {
      return orderBy(props.deviations, ['created'], ['desc'])
    })

    return { deviationScrollRef, sortedDeviations }
  },
})
