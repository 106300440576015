
import { computed, defineComponent, ref } from 'vue'
import AppLogo from '@/components/AppLogo.vue'
import AppDrawer from '@/components/AppDrawer.vue'
import { useRouter, useRoute } from 'vue-router'

export default defineComponent({
  name: 'LayoutDefault',

  components: {
    AppLogo,
    AppDrawer,
  },

  props: {
    meta: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const router = useRouter()
    const route = useRoute()
    const drawerRight = ref(false)

    function goToStartPage() {
      router.push('/')
    }

    function goBack() {
      router.back()
    }

    const showBackButton = computed(() => {
      return route.path !== '/register'
    })

    return { drawerRight, goToStartPage, showBackButton, goBack }
  },
})
