<template>
  <q-scroll-area
    style="height: 200px; max-width: 100vw"
    :visible="false"
    ref="refScrollArea"
  >
    <div class="flex q-px-lg flex no-wrap items-end q-pt-md">
      <VehicleDeviationMap :vehicle="vehicle" />
    </div>
  </q-scroll-area>
</template>

<script lang="ts">
import { Vehicle } from '@/types/vehicle'
import { QScrollArea } from 'quasar'
import { defineComponent, PropType, ref } from 'vue'
import VehicleDeviationMap from './VehicleDeviationMap.vue'

export default defineComponent({
  name: 'VehicleDeviationSlider',

  components: {
    VehicleDeviationMap,
  },

  props: {
    vehicle: {
      type: Object as PropType<Vehicle>,
    },
  },

  setup() {
    const refScrollArea = ref<QScrollArea | null>(null)

    return {
      refScrollArea,
    }
  },
})
</script>
