
import { Vehicle } from '@/types/vehicle'
import { defineComponent, PropType } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'TrainVehicleCard',

  props: {
    trainUuid: String,
    vehicle: Object as PropType<Vehicle>,
  },

  setup(props) {
    const router = useRouter()

    function goToVehicle() {
      router.push(
        `/register/train/${props.trainUuid}/vehicle/${props?.vehicle?.uuid}`
      )
    }

    return {
      goToVehicle,
    }
  },
})
