
import SignInForm from './components/SignInForm.vue'
import AppLogo from '@/components/AppLogo.vue'

export default {
  name: 'SignIn',

  components: {
    SignInForm,
    AppLogo,
  },
}
