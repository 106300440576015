
import InlineSvg from 'vue-inline-svg'
import { defineComponent, PropType } from 'vue'
import { Vehicle } from '@/types/vehicle'
import { VehicleType } from '@/types/vehicle-type'
/* eslint-disable */

type VehicleMap = {
  [key in VehicleType]: unknown
}

const VEHICLE_MAP: VehicleMap = {
  B2: require('@/assets/vehicles/B2.svg'),
  B9: require('@/assets/vehicles/B9.svg'),
  BC4: require('@/assets/vehicles/BC4.svg'),
  BF4: require('@/assets/vehicles/BF4.svg'),
  BFS9: require('@/assets/vehicles/BFS9.svg'),
  'RC-6': require('@/assets/vehicles/RC6.svg'),
  R12: require('@/assets/vehicles/R12.svg'),
  WL4: require('@/assets/vehicles/WL4.svg'),
  WL6: require('@/assets/vehicles/WL6.svg'),
}

/* eslint-enable */

export default defineComponent({
  name: 'VehicleDeviationMap',

  components: {
    InlineSvg,
  },

  props: {
    vehicle: {
      type: Object as PropType<Vehicle>,
      required: true,
    },
  },

  setup(props) {
    const src = VEHICLE_MAP[props.vehicle.vehicleTypeName as VehicleType]
    return {
      src,
    }
  },
})
