<template>
  <q-card>
    <q-card-section class="row items-center">
      <q-icon size="xs" name="mdi-alert-circle-outline" color="negative" />
      <span class="q-ml-sm text-subtitle1 text-bold">Ej behörighet</span>
    </q-card-section>

    <q-card-section class="row text-grey-8">
      Du har ej behörighet för denna applikation, försök med en annan användare.
    </q-card-section>

    <q-card-actions align="right">
      <q-btn
        v-if="$route.path !== '/sign-in'"
        flat
        label="Logga ut"
        color="primary"
        @click="onSignOut"
      />
      <q-btn
        v-else
        flat
        label="Ok"
        color="primary"
        @click="showPermissionModal = false"
      />
    </q-card-actions>
  </q-card>
</template>

<script lang="ts">
import { useProfile } from '@/compositions/useProfile'
import { useUser } from '@/compositions/useUser'
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    const { showPermissionModal, profile } = useProfile()
    const { signOut } = useUser()
    function onSignOut() {
      showPermissionModal.value = false
      profile.value = null
      signOut()
    }
    return { showPermissionModal, onSignOut }
  },
})
</script>
