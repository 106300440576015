import { render } from "./Arrivals.vue?vue&type=template&id=6f9ef60c"
import script from "./Arrivals.vue?vue&type=script&lang=ts"
export * from "./Arrivals.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QInput from 'quasar/src/components/input/QInput.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInput,QPopupProxy,QDate,QIcon,QSeparator,QScrollArea,QSpinner});
