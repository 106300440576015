import { render } from "./LayoutDefault.vue?vue&type=template&id=5e68b278"
import script from "./LayoutDefault.vue?vue&type=script&lang=ts"
export * from "./LayoutDefault.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QPage from 'quasar/src/components/page/QPage.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QHeader,QToolbar,QBtn,QToolbarTitle,QDrawer,QPageContainer,QPage});
