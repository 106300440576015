<template>
  <transition
    enter-active-class="animated animate__fadeIn"
    leave-active-class="animated animate__fadeOut"
    mode="out-in"
    :duration="140"
  >
    <div
      v-if="loading"
      class="flex full-height items-center justify-center"
      style="min-height: inherit"
    >
      <q-spinner color="primary" size="3em" :thickness="2" />
    </div>
    <div v-else>
      <TrainHeader :train="train" />
      <div class="q-px-md">
        <q-input borderless v-model="vehicleSearch" label="Sök fordon">
          <template v-slot:prepend>
            <q-icon name="mdi-magnify" />
          </template>
        </q-input>
      </div>
      <q-separator />
      <q-scroll-area style="height: calc(100vh - 50px - 72px - 56px)">
        <div class="fit">
          <TrainListHeaderGroup>Utförda</TrainListHeaderGroup>
          <div class="q-pa-sm" v-if="filteredCompleteVehicles.length">
            <TrainVehicleCard
              :train-uuid="train.uuid"
              :vehicle="vehicle"
              v-for="vehicle in filteredCompleteVehicles"
              :key="vehicle.uuid"
            />
          </div>
          <div
            v-else
            class="q-pa-sm text-grey-7 flex justify-center items-center"
          >
            <q-icon name="mdi-information-outline" class="q-mr-sm" />
            Inga utförda
          </div>
          <TrainListHeaderGroup>Övriga</TrainListHeaderGroup>
          <div class="q-pa-sm">
            <TrainVehicleCard
              :train-uuid="train.uuid"
              :vehicle="vehicle"
              v-for="vehicle in filteredNotCompleteVehicles"
              :key="vehicle.uuid"
            />
          </div>
        </div>
      </q-scroll-area>
    </div>
  </transition>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import TrainHeader from './components/TrainHeader.vue'
import TrainVehicleCard from './components/TrainVehicleCard.vue'
import { useTrain } from '@/compositions/useTrain'
import { useVehicle } from '@/compositions/useVehicle'
import { useRoute } from 'vue-router'
import TrainListHeaderGroup from './components/TrainListHeaderGroup.vue'
import { Vehicle } from '@/types/vehicle'

export default defineComponent({
  name: 'Train',

  components: {
    TrainHeader,
    TrainVehicleCard,
    TrainListHeaderGroup,
  },

  setup() {
    const { fetchTrain, trainLoading, train } = useTrain()
    const { fetchVehicles, vehicleLoading, vehicles } = useVehicle()
    const route = useRoute()

    const vehicleSearch = ref('')

    const trainUuid =
      typeof route.params.trainUuid === 'string'
        ? route.params.trainUuid
        : 'missing'

    fetchTrain(trainUuid)
    fetchVehicles()

    const loading = computed(() => {
      return (
        trainLoading.value ||
        !train.value ||
        !vehicles.value ||
        vehicleLoading.value
      )
    })

    function getVehicleFilterText(vehicle: Vehicle) {
      return `${vehicle.vehicleTypeName} ${vehicle.name} ${vehicle.description}`.toLowerCase()
    }

    function isCompleted(vehicle: Vehicle) {
      return train.value?.activities.every((activity) => {
        return activity.vehicles.some(
          (activityVehicle) => activityVehicle.vehicleUuid === vehicle.uuid
        )
      })
    }

    const filteredCompleteVehicles = computed(() => {
      return (vehicles.value || []).filter(isCompleted).filter((vehicle) => {
        const vehicleText = getVehicleFilterText(vehicle)
        return (
          !vehicleSearch.value.length ||
          vehicleText.includes(vehicleSearch.value.toLowerCase())
        )
      })
    })

    const filteredNotCompleteVehicles = computed(() => {
      return (vehicles.value || [])
        .filter((vehicle) => !isCompleted(vehicle))
        .filter((vehicle) => {
          const vehicleText = getVehicleFilterText(vehicle)
          return (
            !vehicleSearch.value.length ||
            vehicleText.includes(vehicleSearch.value.toLowerCase())
          )
        })
    })

    return {
      loading,
      vehicles,
      train,
      vehicleSearch,
      filteredCompleteVehicles,
      filteredNotCompleteVehicles,
    }
  },
})
</script>
