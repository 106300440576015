<template>
  <q-form ref="signInForm" @submit="onSignIn">
    <div class="row">
      <div class="col">
        <q-input
          v-model="model.email"
          label="E-mail"
          :rules="[requiredRule]"
          clearable
        >
          <template v-slot:prepend><q-icon name="mdi-account" /></template>
        </q-input>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <q-input
          v-model="model.password"
          label="Lösenord"
          :type="!showPassword ? 'password' : 'text'"
          :rules="[requiredRule]"
        >
          <template v-slot:prepend><q-icon name="mdi-lock" /></template>

          <template v-slot:append>
            <q-icon
              :name="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
              class="cursor-pointer"
              @click="showPassword = !showPassword"
            />
          </template>
        </q-input>
      </div>
    </div>
    <q-slide-transition>
      <div class="row" v-if="signInErrorMessage">
        <div class="col q-mb-sm">
          <q-banner dense class="text-white bg-red" rounded>
            <template v-slot:avatar>
              <q-icon size="xs" name="mdi-alert-circle-outline" color="white" />
            </template>
            {{ signInErrorMessage }}
          </q-banner>
        </div>
      </div>
    </q-slide-transition>
    <div class="row">
      <div class="col justify-end flex">
        <q-btn
          color="primary"
          label="Logga in"
          type="submit"
          :loading="signInLoading"
        />
      </div>
    </div>
  </q-form>
</template>

<script lang="ts">
import { QForm } from 'quasar'
import { defineComponent, reactive, ref } from 'vue'
import { useUser } from '@/compositions/useUser'
import { useRouter } from 'vue-router'
import { required as requiredRule } from '@/common/formValidationRules'

export default defineComponent({
  name: 'SignInForm',

  setup() {
    const { signIn, signInErrorMessage, signInLoading } = useUser()
    const router = useRouter()
    const signInForm = ref<QForm | null>(null)
    const showPassword = ref(false)

    const model = reactive({
      email: '',
      password: '',
    })

    const onSignIn = async () => {
      const isValid = await signInForm.value?.validate()
      if (!isValid) return
      try {
        await signIn(model)
        router.push('/')
      } catch (error) {
        console.log(error)
      }
    }

    return {
      onSignIn,
      signInForm,
      signInErrorMessage,
      signInLoading,
      model,
      requiredRule,
      showPassword,
    }
  },
})
</script>
