
import { defineComponent, PropType, computed } from 'vue'
import { useDeviationType } from '@/compositions/useDeviationType'
import { useDeviation } from '../../compositions/useDeviation'
import { useDeviationForm } from '../../compositions/useDeviationForm'
import { useVehicleTypePlace } from '@/compositions/useVehicleTypePlace'
import { Deviation } from '@/types/deviation'

export default defineComponent({
  name: 'VehicleDeviationCard',

  props: {
    deviation: {
      type: Object as PropType<Deviation>,
      required: true,
    },
  },

  setup(props) {
    const { vehicleTypePlaces, vehicleTypePlaceLoading } = useVehicleTypePlace()
    const { deviationTypes } = useDeviationType()
    const {
      deviationEditMode,
      showDeviationFormModal,
      selectedDeviationForm,
      selectedDeviation,
      selectedPhotoSrc,
    } = useDeviation()
    const { setDeviationForm, deviationPhotoSrc } = useDeviationForm()

    const deviationType = computed(() => {
      return deviationTypes.value.find(
        (dt) => dt.uuid === props.deviation.deviationTypeUuid
      )
    })

    function openEditDevition() {
      const parsedDeviationForm: Deviation = {
        deviationTypeUuid: props.deviation.deviationTypeUuid,
        vehicleUuid: props.deviation.vehicleUuid,
        vehicleTypePlaceUuid: props.deviation.vehicleTypePlaceUuid,
        description: props.deviation.description,
      }
      deviationPhotoSrc.value = null
      selectedPhotoSrc.value = null
      selectedDeviation.value = Object.assign({}, props.deviation)

      selectedDeviationForm.value = Object.assign({}, parsedDeviationForm)
      if (props.deviation.photos && props.deviation.photos.length) {
        selectedPhotoSrc.value =
          props.deviation.photos[props.deviation.photos.length - 1]?.url || null
        deviationPhotoSrc.value =
          props.deviation.photos[props.deviation.photos.length - 1]?.url || null
      }

      setDeviationForm(Object.assign({}, parsedDeviationForm))

      deviationEditMode.value = true
      showDeviationFormModal.value = true
    }

    const place = computed(() => {
      if (!props.deviation.vehicleTypePlaceUuid) return false
      return vehicleTypePlaces.value.find(
        (vtp) => vtp.uuid === props.deviation.vehicleTypePlaceUuid
      )
    })

    return {
      openEditDevition,
      deviationType,
      place,
      vehicleTypePlaceLoading,
    }
  },
})
