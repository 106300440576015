
import { defineComponent } from 'vue'
import { format } from 'date-fns'

export default defineComponent({
  name: 'TrainHeader',
  props: {
    train: Object,
  },

  setup(props) {
    const arrivalTime = props.train
      ? format(new Date(props.train.arrival), 'HH:mm')
      : '-'
    return {
      arrivalTime,
    }
  },
})
