
import TrainVehicleDeviationImageFullScreenDialog from './VehicleDeviationImageFullScreenDialog.vue'
import { defineComponent, ref, onMounted } from 'vue'
import { useDeviationForm } from '../../compositions/useDeviationForm'

export default defineComponent({
  name: 'VehicleDeviationPhotoInput',

  components: {
    TrainVehicleDeviationImageFullScreenDialog,
  },

  setup() {
    const showFullscreenImage = ref(false)
    const captureImageRef = ref<HTMLInputElement | null>(null)
    const { deviationPhotoSrc, deviationPhotoFile } = useDeviationForm()

    onMounted(() => {
      setTimeout(() => {
        if (!captureImageRef.value) return
        captureImageRef.value.addEventListener('change', (e) => {
          const files = (e.target as HTMLInputElement).files
          const file = files?.[0]
          if (file) {
            deviationPhotoFile.value = file
            deviationPhotoSrc.value = URL.createObjectURL(file)
          }
        })
      }, 0)
    })

    async function removePhoto() {
      deviationPhotoFile.value = null
      deviationPhotoSrc.value = null
    }

    function takePhoto() {
      if (captureImageRef?.value?.value) {
        captureImageRef.value.value = ''
      }

      if (!captureImageRef.value) return

      captureImageRef.value.click()
    }

    return {
      showFullscreenImage,
      captureImageRef,
      deviationPhotoSrc,
      removePhoto,
      takePhoto,
    }
  },
})
