<template>
  <q-card class="q-mb-sm" bordered flat>
    <q-card-section class="q-pa-sm">
      <div class="flex justify-between items-end">
        <div class="text-subtitle1 text-bold q-pl-sm">
          {{ activity.activity }}
        </div>
        <transition
          enter-active-class="animated animate__zoomIn"
          leave-active-class="animated animate__zoomOut"
          mode="out-in"
          :duration="70"
        >
          <q-btn
            v-if="!showUnregisterButton"
            color="white"
            text-color="positive"
            icon-right="mdi-check-circle-outline"
            label="Utför"
            @click="onConfirm('REGISTER')"
          />
          <q-btn
            v-else
            color="white"
            text-color="warning"
            label="Ångra utförd"
            @click="onConfirm('UNREGISTER')"
          />
        </transition>
      </div>
    </q-card-section>
  </q-card>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue'
import { Vehicle } from '@/types/vehicle'
import { Train } from '@/types/train'
import { TrainActivity } from '@/types/train-activity'
import {
  ConfirmDialogPayload,
  ConfirmDialogType,
} from './VehicleActivities.vue'

export default defineComponent({
  name: 'VehicleActivityCard',

  props: {
    train: Object as PropType<Train>,
    activity: Object as PropType<TrainActivity>,
    vehicle: Object as PropType<Vehicle>,
  },

  emits: ['confirm'],

  setup(props, { emit }) {
    const showUnregisterButton = computed(() =>
      props?.activity?.vehicles.some((vehicle) => {
        return vehicle.vehicleUuid === props?.vehicle?.uuid
      })
    )

    function onConfirm(type: ConfirmDialogType) {
      const payload: ConfirmDialogPayload = {
        type,
        vehicleUuid: props?.vehicle?.uuid || null,
        activityUuid: props?.activity?.uuid || null,
      }
      emit('confirm', payload)
    }

    return {
      showUnregisterButton,
      onConfirm,
    }
  },
})
</script>
