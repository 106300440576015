
import { defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'VehicleDeviationImageFullScreenDialog',

  emits: ['update:modelValue'],

  props: {
    src: String,
    modelValue: Boolean,
  },

  setup(props, { emit }) {
    const show = computed({
      get() {
        return props.modelValue
      },

      set(value: boolean) {
        emit('update:modelValue', value)
      },
    })

    return { show }
  },
})
