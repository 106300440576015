
import { computed, defineComponent } from 'vue'
import { useVehicleTypePlace } from '@/compositions/useVehicleTypePlace'
import { useDeviationForm } from '../../compositions/useDeviationForm'
import { required as requiredRule } from '@/common/formValidationRules'
import { useVehicle } from '@/compositions/useVehicle'

export default defineComponent({
  name: 'VehicleDeviationPlaceInput',

  setup() {
    const {
      fetchVehicleTypePlaces,
      vehicleTypePlaceLoading,
      vehicleTypePlaces,
    } = useVehicleTypePlace()
    const { vehicle } = useVehicle()

    const { deviationForm } = useDeviationForm()

    fetchVehicleTypePlaces()

    const filteredVehicleTypePlaces = computed(() => {
      return vehicleTypePlaces.value.filter((vehicleType) => {
        return vehicleType.vehicleTypeUuid === vehicle?.value?.vehicleTypeUuid
      })
    })

    return {
      vehicleTypePlaceLoading,
      filteredVehicleTypePlaces,
      deviationForm,
      requiredRule,
    }
  },
})
