import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

const app = createApp(App)

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    app,
    dsn: 'https://75c33731288d4068b6c894b37ff70235@o261284.ingest.sentry.io/5912532',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  })
}

app.use(Quasar, quasarUserOptions)
app.use(router)
app.mount('#app')
