import { Ref, ref } from 'vue'
import { getProfile } from '@/api/profile.api'
import { Profile } from '@/types/profile'

interface UseProfile {
  profile: Ref<Profile | null>
  fetchProfile: () => Promise<Profile>
  hasPermission: (permission: PERMISSION) => boolean
  showPermissionModal: Ref<boolean>
}

type PERMISSION = 'mobile.view' | 'mobile.test' | 'mobile.t'

const profile = ref<Profile | null>(null)
const showPermissionModal = ref<boolean>(false)

export const useProfile = (): UseProfile => {
  async function fetchProfile() {
    const { data } = await getProfile()
    profile.value = data
    return profile.value
  }

  function hasPermission(permission: PERMISSION) {
    if (!profile.value) return false

    return profile.value.permissions
      .map((permission) => permission.name)
      .includes(permission)
  }

  return { profile, fetchProfile, hasPermission, showPermissionModal }
}
