<template>
  <q-scroll-area class="fit">
    <div class="bg-primary text-white">
      <div class="q-pa-md">
        <q-icon size="md" name="mdi-account-circle" class="q-mb-sm" />
        <div>{{ profile?.email }}</div>
      </div>
      <q-separator />
    </div>
    <q-list class="absolute-bottom">
      <template v-for="(menuItem, index) in menuList" :key="index">
        <q-item
          clickable
          :active="menuItem.label === 'Outbox'"
          v-ripple
          @click="menuItem.action"
        >
          <q-item-section avatar>
            <q-icon :name="menuItem.icon" />
          </q-item-section>
          <q-item-section>
            {{ menuItem.label }}
          </q-item-section>
        </q-item>
        <q-separator :key="'sep' + index" v-if="menuItem.separator" />
      </template>
    </q-list>
  </q-scroll-area>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useUser } from '@/compositions/useUser'
import { useProfile } from '@/compositions/useProfile'

export default defineComponent({
  name: 'AppDrawer',

  setup() {
    const { signOut } = useUser()
    const { profile } = useProfile()

    const menuList = [
      {
        label: 'Logga ut',
        icon: 'mdi-logout-variant',
        action: onSignOut,
        separator: true,
      },
    ]

    function onSignOut() {
      profile.value = null
      signOut()
    }

    return { onSignOut, menuList, profile }
  },
})
</script>
