
import TrainVehicleConfirmCheckDialog from './VehicleConfirmCheckDialog.vue'
import TrainVehicleConfirmUnCheckDialog from './VehicleConfirmUnCheckDialog.vue'
import VehicleActivityCard from './VehicleActivityCard.vue'
import { defineComponent, PropType, ref } from 'vue'

import { Train } from '@/types/train'
import { TrainActivity } from '@/types/train-activity'
import { Vehicle } from '@/types/vehicle'

export type ConfirmDialogType = 'REGISTER' | 'UNREGISTER'

export interface ConfirmDialogPayload {
  type: ConfirmDialogType
  vehicleUuid: string | null
  activityUuid: string | null
}

export default defineComponent({
  name: 'VehicleActivities',

  components: {
    VehicleActivityCard,
    TrainVehicleConfirmCheckDialog,
    TrainVehicleConfirmUnCheckDialog,
  },

  props: {
    train: Object as PropType<Train>,
    vehicle: Object as PropType<Vehicle>,
    activities: Array as PropType<TrainActivity[]>,
  },

  setup() {
    const showConfirmDialog = ref(false)
    const showConfirmUnregisterDialog = ref(false)
    const selectedVehicleUuid = ref<string | null>(null)
    const selectedActivityUuid = ref<string | null>(null)

    function openConfirmDialog(payload: ConfirmDialogPayload) {
      selectedVehicleUuid.value = payload.vehicleUuid
      selectedActivityUuid.value = payload.activityUuid

      if (payload.type === 'REGISTER') {
        showConfirmDialog.value = true
      }

      if (payload.type === 'UNREGISTER') {
        showConfirmUnregisterDialog.value = true
      }
    }

    return {
      showConfirmDialog,
      showConfirmUnregisterDialog,
      openConfirmDialog,
      selectedVehicleUuid,
      selectedActivityUuid,
    }
  },
})
