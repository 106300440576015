import { getVehicleTypePlaces } from '@/api/vehicle-type-place.api'
import { VehicleTypePlace } from '@/types/vehicle-type-place'
import { ref, Ref } from 'vue'

type VehicleTypePlaceErrorMessage = string | null

interface UseVehicleTypePlace {
  vehicleTypePlaceLoading: Ref<boolean>
  vehicleTypePlaceErrorMessage: Ref<VehicleTypePlaceErrorMessage>
  fetchVehicleTypePlaces: () => Promise<void>
  vehicleTypePlaces: Ref<VehicleTypePlace[] | []>
}

const vehicleTypePlaces = ref<VehicleTypePlace[] | []>([])

export const useVehicleTypePlace = (): UseVehicleTypePlace => {
  const vehicleTypePlaceLoading = ref(false)
  const vehicleTypePlaceErrorMessage = ref<VehicleTypePlaceErrorMessage>(null)

  async function fetchVehicleTypePlaces() {
    vehicleTypePlaceLoading.value = true
    try {
      const { data } = await getVehicleTypePlaces()
      vehicleTypePlaces.value = data
    } catch (error) {
      console.log(error)
      vehicleTypePlaceErrorMessage.value =
        'Fel: Kunde inte hämta avvikelse platstyperna'
    }
    vehicleTypePlaceLoading.value = false
  }

  return {
    vehicleTypePlaceLoading,
    vehicleTypePlaceErrorMessage,
    fetchVehicleTypePlaces,
    vehicleTypePlaces,
  }
}
