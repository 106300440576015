<template>
  <q-layout view="lHh lpr lFf">
    <q-dialog v-model="showErrorModal" persistent>
      <AppErrorModal v-if="showErrorModal" />
    </q-dialog>
    <q-dialog v-model="showPermissionModal" persistent>
      <AppPermissionModal v-if="showPermissionModal" />
    </q-dialog>
    <router-view v-slot="{ Component, route }">
      <transition
        enter-active-class="animated animate__fadeIn"
        leave-active-class="animated animate__fadeOut"
        mode="out-in"
        :duration="100"
      >
        <template v-if="route.meta.layout">
          <component
            :is="route.meta.layout"
            :meta="route.meta"
            :key="route.path"
          >
            <component :is="Component" />
          </component>
        </template>
        <component v-else :is="Component" :key="route.path" />
      </transition>
    </router-view>
  </q-layout>
</template>

<script lang="ts">
import AppErrorModal from '@/components/AppErrorModal.vue'
import AppPermissionModal from '@/components/AppPermissionModal.vue'
import { ref, defineComponent } from 'vue'
import { useError } from '@/compositions/useError'
import { useProfile } from '@/compositions/useProfile'

export default defineComponent({
  name: 'App',

  components: { AppErrorModal, AppPermissionModal },

  setup() {
    const { showErrorModal } = useError()
    const { showPermissionModal } = useProfile()
    return {
      showErrorModal,
      showPermissionModal,
      leftDrawerOpen: ref(false),
    }
  },
})
</script>
