
import { v4 as uuidv4 } from 'uuid'
import { defineComponent, PropType, ref, computed } from 'vue'
import QForm from 'quasar/src/components/form/QForm.js';
import { useDeviationForm } from '../../compositions/useDeviationForm'
import { useDeviation } from '../../compositions/useDeviation'
import { useProfile } from '@/compositions/useProfile'
import VehicleDeviationFormDialogHeader from './VehicleDeviationFormDialogHeader.vue'
import VehicleDeviationSlider from './VehicleDeviationSlider.vue'
import VehicleDeviationPhotoInput from './VehicleDeviationPhotoInput.vue'
import VehicleDeviationCategoryInput from './VehicleDeviationCategoryInput.vue'
import VehicleDeviationPlaceInput from './VehicleDeviationPlaceInput.vue'
import VehicleDeviationCheckDialog from './VehicleDeviationCheckDialog.vue'
import isEqual from 'lodash.isequal'
import { Train } from '@/types/train'
import { Vehicle } from '@/types/vehicle'

export default defineComponent({
  name: 'VehicleDeviationFormDialog',

  components: {
    VehicleDeviationSlider,
    VehicleDeviationFormDialogHeader,
    VehicleDeviationPhotoInput,
    VehicleDeviationCategoryInput,
    VehicleDeviationPlaceInput,
    VehicleDeviationCheckDialog,
  },

  emits: ['close'],

  props: {
    modelValue: Boolean,
    train: Object as PropType<Train>,
    vehicle: Object as PropType<Vehicle>,
  },

  setup(props) {
    const {
      deviationForm,
      createDeviation,
      updateDeviation,
      createDeviationLoading,
      updateDeviationLoading,
      deviationPhotoSrc,
    } = useDeviationForm()

    const {
      showDeviationFormModal,
      deviationEditMode,
      selectedDeviationForm,
      selectedDeviation,
      selectedPhotoSrc,
    } = useDeviation()

    const { profile } = useProfile()

    const deviationFormRef = ref<QForm | null>(null)
    const showConfirmDeviationModal = ref(false)

    async function onConfirm() {
      const isValid = await deviationFormRef.value?.validate()
      if (!props.vehicle || !isValid) return

      if (!deviationEditMode.value) {
        deviationForm.value.vehicleUuid = props.vehicle.uuid
        deviationForm.value.uuid = uuidv4()

        await createDeviation()
      } else {
        deviationForm.value.completed = null
        deviationForm.value.completedDescription = null
        if (selectedDeviation?.value?.uuid) {
          await updateDeviation(
            selectedDeviation.value.uuid,
            deviationForm.value
          )
        }
      }

      showDeviationFormModal.value = false
    }

    const isDeviationFormDiff = computed(() => {
      return !(
        isEqual(deviationForm.value, selectedDeviationForm.value) &&
        deviationPhotoSrc.value === selectedPhotoSrc.value
      )
    })

    const isSameUser = computed(() => {
      if (!profile.value || !selectedDeviation.value) return false
      return profile.value.uuid === selectedDeviation.value.userUuid
    })

    return {
      onConfirm,
      deviationForm,
      deviationFormRef,
      createDeviationLoading,
      updateDeviationLoading,
      deviationEditMode,
      isDeviationFormDiff,
      showConfirmDeviationModal,
      isSameUser,
    }
  },
})
