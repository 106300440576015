import { getLocations } from '@/api/location.api'
import { Location } from '@/types/location'
import { ref, Ref } from 'vue'

type LocationErrorMessage = string | null

interface UseLocation {
  locationLoading: Ref<boolean>
  locationErrorMessage: Ref<LocationErrorMessage>
  fetchLocations: () => Promise<void>
  locations: Ref<Location[] | null>
}

type Locations = Location[] | null

export const useLocation = (): UseLocation => {
  const locations = ref<Locations>(null)
  const locationLoading = ref(false)
  const locationErrorMessage = ref<LocationErrorMessage>(null)

  async function fetchLocations() {
    locationLoading.value = true
    try {
      const { data } = await getLocations()
      locations.value = data
    } catch (error) {
      console.log(error)
      locationErrorMessage.value = 'Fel: Kunde inte hämta platserna'
    }
    locationLoading.value = false
  }

  return { locationLoading, locationErrorMessage, fetchLocations, locations }
}
