import { render } from "./TrainHeader.vue?vue&type=template&id=f4060e56"
import script from "./TrainHeader.vue?vue&type=script&lang=ts"
export * from "./TrainHeader.vue?vue&type=script&lang=ts"

const cssModules = script.__cssModules = {}
import style0 from "./TrainHeader.vue?vue&type=style&index=0&id=f4060e56&module=true&lang=css"
cssModules["$style"] = style0
script.render = render

export default script
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QIcon});
