
import { defineComponent } from 'vue'
import { useLocation } from '@/compositions/useLocation'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'Register',

  setup() {
    const router = useRouter()
    const { fetchLocations, locationLoading, locations } = useLocation()

    fetchLocations()

    function onSelectLocation(signature: string) {
      router.push(`/register/${signature}/arrivals`)
    }
    return {
      locationLoading,
      onSelectLocation,
      locations,
    }
  },
})
