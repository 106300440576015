
import { defineComponent, PropType, computed } from 'vue'
import { Vehicle } from '@/types/vehicle'
import { Train } from '@/types/train'
import { TrainActivity } from '@/types/train-activity'
import {
  ConfirmDialogPayload,
  ConfirmDialogType,
} from './VehicleActivities.vue'

export default defineComponent({
  name: 'VehicleActivityCard',

  props: {
    train: Object as PropType<Train>,
    activity: Object as PropType<TrainActivity>,
    vehicle: Object as PropType<Vehicle>,
  },

  emits: ['confirm'],

  setup(props, { emit }) {
    const showUnregisterButton = computed(() =>
      props?.activity?.vehicles.some((vehicle) => {
        return vehicle.vehicleUuid === props?.vehicle?.uuid
      })
    )

    function onConfirm(type: ConfirmDialogType) {
      const payload: ConfirmDialogPayload = {
        type,
        vehicleUuid: props?.vehicle?.uuid || null,
        activityUuid: props?.activity?.uuid || null,
      }
      emit('confirm', payload)
    }

    return {
      showUnregisterButton,
      onConfirm,
    }
  },
})
