import { render } from "./VehicleDeviationImageFullScreenDialog.vue?vue&type=template&id=6689a347"
import script from "./VehicleDeviationImageFullScreenDialog.vue?vue&type=script&lang=ts"
export * from "./VehicleDeviationImageFullScreenDialog.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QBar from 'quasar/src/components/bar/QBar.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QImg from 'quasar/src/components/img/QImg.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QCard,QBar,QSpace,QBtn,QImg});qInstall(script, 'directives', {ClosePopup});
