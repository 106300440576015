
import { defineComponent } from 'vue'
import { useUser } from '@/compositions/useUser'
import { useProfile } from '@/compositions/useProfile'

export default defineComponent({
  name: 'AppDrawer',

  setup() {
    const { signOut } = useUser()
    const { profile } = useProfile()

    const menuList = [
      {
        label: 'Logga ut',
        icon: 'mdi-logout-variant',
        action: onSignOut,
        separator: true,
      },
    ]

    function onSignOut() {
      profile.value = null
      signOut()
    }

    return { onSignOut, menuList, profile }
  },
})
