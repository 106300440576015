<template>
  <InlineSvg
    width="32"
    height="32"
    :src="require('@/assets/logo.svg')"
    aria-label="ISS Fordonsvård"
  />
</template>

<script lang="ts">
import InlineSvg from 'vue-inline-svg'
import { defineComponent } from 'vue'

enum SIZE_DIMENSIONS {
  sm = '24px',
  lg = '54px',
}

type Size = keyof typeof SIZE_DIMENSIONS

export default defineComponent({
  name: 'AppLogo',

  components: {
    InlineSvg,
  },

  props: {
    size: {
      type: String,
      default: 'sm',
      validator: (size: string): boolean =>
        Object.keys(SIZE_DIMENSIONS).includes(size),
    },
  },

  setup(props) {
    const style = {
      height: SIZE_DIMENSIONS[props.size as Size],
    }

    return { style }
  },
})
</script>
