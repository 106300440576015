<template>
  <q-page-container>
    <q-page class="flex justify-center items-center q-pa-sm bg-primary">
      <q-card class="full-width" style="max-width: 400px">
        <q-card-section class="flex items-center">
          <AppLogo size="lg" />
          <q-separator class="q-ml-sm" vertical inset />
          <h5 class="q-ml-sm q-ma-none text-weight-bold">Fordonsvård</h5>
        </q-card-section>
        <q-card-section>
          <SignInForm />
        </q-card-section>
      </q-card>
    </q-page>
  </q-page-container>
</template>

<script lang="ts">
import SignInForm from './components/SignInForm.vue'
import AppLogo from '@/components/AppLogo.vue'

export default {
  name: 'SignIn',

  components: {
    SignInForm,
    AppLogo,
  },
}
</script>
