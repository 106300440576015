<template>
  <div class="q-pa-md flex justify-start">
    <div>
      <div class="text-h6 q-mb-xs" style="line-height: 1">
        Tåg {{ train.train }}
      </div>
      <div
        class="text-subtitle1 text-grey-6 flex items-center"
        style="line-height: 1"
      >
        {{ train.place }}
        <q-icon name="mdi-arrow-right" class="q-px-xs" />
        {{ arrivalTime }}
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { format } from 'date-fns'

export default defineComponent({
  name: 'TrainHeader',
  props: {
    train: Object,
  },

  setup(props) {
    const arrivalTime = props.train
      ? format(new Date(props.train.arrival), 'HH:mm')
      : '-'
    return {
      arrivalTime,
    }
  },
})
</script>

<style module>
.sticky {
  position: sticky;
  top: 0;
}
</style>
