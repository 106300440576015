import { render } from "./VehicleDeviationScrollList.vue?vue&type=template&id=293ef47f"
import script from "./VehicleDeviationScrollList.vue?vue&type=script&lang=ts"
export * from "./VehicleDeviationScrollList.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QScrollArea,QIcon});
