<template>
  <q-dialog v-model="showConfirmDeviationModal" persistent>
    <VehicleDeviationCheckDialog
      v-if="showConfirmDeviationModal"
      @close="() => (showConfirmDeviationModal = false)"
    />
  </q-dialog>
  <q-card flat class="full-width">
    <q-form ref="deviationFormRef" @submit="onConfirm">
      <VehicleDeviationFormDialogHeader :vehicle="vehicle" :train="train" />
      <VehicleDeviationSlider :vehicle="vehicle" />
      <q-card-section class="q-pt-sm">
        <div class="row" style="height: 130px">
          <div class="col-7">
            <div class="row">
              <div class="col-12">
                <VehicleDeviationCategoryInput />
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <VehicleDeviationPlaceInput />
              </div>
            </div>
          </div>
          <div class="col-5">
            <div class="flex justify-end items-center full-height q-pl-md">
              <VehicleDeviationPhotoInput />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <q-input
              dense
              rows="1"
              label="Fritext"
              v-model="deviationForm.description"
              type="textarea"
            />
          </div>
        </div>
      </q-card-section>
      <q-card-actions>
        <transition
          enter-active-class="animated animate__zoomIn"
          leave-active-class="animated animate__zoomOut"
          mode="out-in"
          :duration="70"
        >
          <q-btn
            v-if="!deviationEditMode"
            class="q-ml-auto"
            color="primary"
            label="Lägg till"
            type="submit"
            :loading="createDeviationLoading"
          />
          <q-btn
            v-else-if="deviationEditMode && isDeviationFormDiff && isSameUser"
            class="q-ml-auto"
            color="primary"
            label="Uppdatera"
            :loading="updateDeviationLoading"
            type="submit"
          />
          <q-btn
            v-else
            class="q-ml-auto"
            color="white"
            text-color="positive"
            icon-right="mdi-check-circle-outline"
            label="Utför"
            @click="showConfirmDeviationModal = true"
            type="button"
          />
        </transition>
      </q-card-actions>
    </q-form>
  </q-card>
</template>

<script lang="ts">
import { v4 as uuidv4 } from 'uuid'
import { defineComponent, PropType, ref, computed } from 'vue'
import { QForm } from 'quasar'
import { useDeviationForm } from '../../compositions/useDeviationForm'
import { useDeviation } from '../../compositions/useDeviation'
import { useProfile } from '@/compositions/useProfile'
import VehicleDeviationFormDialogHeader from './VehicleDeviationFormDialogHeader.vue'
import VehicleDeviationSlider from './VehicleDeviationSlider.vue'
import VehicleDeviationPhotoInput from './VehicleDeviationPhotoInput.vue'
import VehicleDeviationCategoryInput from './VehicleDeviationCategoryInput.vue'
import VehicleDeviationPlaceInput from './VehicleDeviationPlaceInput.vue'
import VehicleDeviationCheckDialog from './VehicleDeviationCheckDialog.vue'
import isEqual from 'lodash.isequal'
import { Train } from '@/types/train'
import { Vehicle } from '@/types/vehicle'

export default defineComponent({
  name: 'VehicleDeviationFormDialog',

  components: {
    VehicleDeviationSlider,
    VehicleDeviationFormDialogHeader,
    VehicleDeviationPhotoInput,
    VehicleDeviationCategoryInput,
    VehicleDeviationPlaceInput,
    VehicleDeviationCheckDialog,
  },

  emits: ['close'],

  props: {
    modelValue: Boolean,
    train: Object as PropType<Train>,
    vehicle: Object as PropType<Vehicle>,
  },

  setup(props) {
    const {
      deviationForm,
      createDeviation,
      updateDeviation,
      createDeviationLoading,
      updateDeviationLoading,
      deviationPhotoSrc,
    } = useDeviationForm()

    const {
      showDeviationFormModal,
      deviationEditMode,
      selectedDeviationForm,
      selectedDeviation,
      selectedPhotoSrc,
    } = useDeviation()

    const { profile } = useProfile()

    const deviationFormRef = ref<QForm | null>(null)
    const showConfirmDeviationModal = ref(false)

    async function onConfirm() {
      const isValid = await deviationFormRef.value?.validate()
      if (!props.vehicle || !isValid) return

      if (!deviationEditMode.value) {
        deviationForm.value.vehicleUuid = props.vehicle.uuid
        deviationForm.value.uuid = uuidv4()

        await createDeviation()
      } else {
        deviationForm.value.completed = null
        deviationForm.value.completedDescription = null
        if (selectedDeviation?.value?.uuid) {
          await updateDeviation(
            selectedDeviation.value.uuid,
            deviationForm.value
          )
        }
      }

      showDeviationFormModal.value = false
    }

    const isDeviationFormDiff = computed(() => {
      return !(
        isEqual(deviationForm.value, selectedDeviationForm.value) &&
        deviationPhotoSrc.value === selectedPhotoSrc.value
      )
    })

    const isSameUser = computed(() => {
      if (!profile.value || !selectedDeviation.value) return false
      return profile.value.uuid === selectedDeviation.value.userUuid
    })

    return {
      onConfirm,
      deviationForm,
      deviationFormRef,
      createDeviationLoading,
      updateDeviationLoading,
      deviationEditMode,
      isDeviationFormDiff,
      showConfirmDeviationModal,
      isSameUser,
    }
  },
})
</script>
