
import { useError } from '@/compositions/useError'
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    const { showErrorModal, error } = useError()
    return { showErrorModal, error }
  },
})
