import { instance } from '@/services/iss-nattag-api.service'
import { RegistrationBody } from '@/types/registration-body'
import { VehicleActivity } from '@/types/vehicle-activity'
import { AxiosResponse } from 'axios'

const URL = 'vehicle-activity'

export const registration = (
  registrationBody: RegistrationBody
): Promise<AxiosResponse<VehicleActivity>> =>
  instance.post(`${URL}`, registrationBody)

export const unregistration = (
  registrationBody: RegistrationBody
): Promise<AxiosResponse<void>> =>
  instance.delete(`${URL}`, {
    data: registrationBody,
  })
