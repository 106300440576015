import { getTrain } from '@/api/train.api'
import { registration, unregistration } from '@/api/vehicle-activity.api'
import { ref, Ref } from 'vue'
import { RegistrationBody } from '@/types/registration-body'
import { Train } from '@/types/train'

type trainErrorMessage = string | null

interface UseTrain {
  trainLoading: Ref<boolean>
  trainErrorMessage: Ref<trainErrorMessage>
  fetchTrain: (trainUuid: string) => Promise<void>
  train: Ref<Train | null>
  registrateVehicle: (registrationBody: RegistrationBody) => Promise<void>
  unregistrateVehicle: (registrationBody: RegistrationBody) => Promise<void>
  registrationLoading: Ref<boolean>
}

const train = ref<Train | null>(null)

export const useTrain = (): UseTrain => {
  const trainLoading = ref(false)
  const registrationLoading = ref(false)
  const trainErrorMessage = ref<trainErrorMessage>(null)

  async function fetchTrain(trainUuid: string) {
    trainLoading.value = true
    try {
      const { data } = await getTrain(trainUuid)
      train.value = data
    } catch (error) {
      console.log(error)
      trainErrorMessage.value = 'Fel: Kunde inte hämta tåg'
    }
    trainLoading.value = false
  }

  async function registrateVehicle(registrationBody: RegistrationBody) {
    if (!train.value) return

    registrationLoading.value = true

    const { data } = await registration(registrationBody)

    train.value.activities = train.value.activities.map((activity) => {
      if (activity.uuid === registrationBody.activityUuid) {
        activity.vehicles.push(data)
      }

      return activity
    })

    registrationLoading.value = false
  }

  async function unregistrateVehicle(registrationBody: RegistrationBody) {
    if (!train.value) return

    registrationLoading.value = true

    await unregistration(registrationBody)

    train.value.activities = train.value.activities.map((activity) => {
      if (activity.uuid === registrationBody.activityUuid) {
        activity.vehicles = activity.vehicles.filter(
          (vehicleActivity) =>
            vehicleActivity.vehicleUuid !== registrationBody.vehicleUuid
        )
      }

      return activity
    })

    registrationLoading.value = false
  }

  return {
    trainLoading,
    trainErrorMessage,
    fetchTrain,
    train,
    registrateVehicle,
    registrationLoading,
    unregistrateVehicle,
  }
}
