
import { defineComponent, ref } from 'vue'
import { useDeviationForm } from '../../compositions/useDeviationForm'
import { useDeviation } from '../../compositions/useDeviation'
import { Deviation } from '@/types/deviation'

export default defineComponent({
  name: 'VehicleDeviationCheckDialog',

  setup() {
    const completedDescription = ref('')
    const { selectedDeviation, showDeviationFormModal, selectedDeviationForm } =
      useDeviation()

    const { completeDeviation, updateDeviationLoading } = useDeviationForm()

    async function onConfirm() {
      if (selectedDeviation?.value?.uuid && selectedDeviationForm.value) {
        const completedDeviationBody: Deviation = {
          ...selectedDeviationForm.value,
          completed: new Date(),
          completedDescription: completedDescription.value || null,
        }
        await completeDeviation(
          selectedDeviation.value.uuid,
          completedDeviationBody
        )
      }

      showDeviationFormModal.value = false
    }

    return { completedDescription, onConfirm, updateDeviationLoading }
  },
})
