<template>
  <q-card-section class="row items-center no-wrap">
    <div
      class="
        text-weight-bold text-body2
        flex
        full-width
        items-center
        justify-between
      "
    >
      Avvikelse
      <q-btn
        color="grey-6"
        flat
        size="sm"
        round
        icon="mdi-close"
        @click="onClose"
      />
    </div>
  </q-card-section>

  <q-card-section class="q-pt-none" style="padding-bottom: 8px">
    <div class="text-subtitle2 q-mt-sm">
      {{ vehicle.type }} {{ vehicle.name }}
    </div>
  </q-card-section>
</template>

<script lang="ts">
import { Train } from '@/types/train'
import { Vehicle } from '@/types/vehicle'
import { PropType, defineComponent } from 'vue'
import { useDeviation } from '../../compositions/useDeviation'

export default defineComponent({
  name: 'VehicleDeviationFormDialogHeader',

  props: {
    train: Object as PropType<Train>,
    vehicle: Object as PropType<Vehicle>,
  },

  setup() {
    const { showDeviationFormModal } = useDeviation()
    function onClose() {
      showDeviationFormModal.value = false
    }

    return { onClose }
  },
})
</script>
