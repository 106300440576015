import { getArrivals } from '@/api/arrival.api'
import { Arrival } from '@/types/arrival'
import { ref, Ref } from 'vue'

type ArrivalErrorMessage = string | null

interface FetchArrivalParams {
  location: string
  trafficDates: string[]
}

interface UseArrival {
  arrivalLoading: Ref<boolean>
  arrivalErrorMessage: Ref<ArrivalErrorMessage>
  fetchArrivals: (fetchArrivalParams: FetchArrivalParams) => Promise<void>
  arrivals: Ref<Arrival[] | null>
}

type Arrivals = Arrival[] | null

export const useArrival = (): UseArrival => {
  const arrivals = ref<Arrivals>(null)
  const arrivalLoading = ref(false)
  const arrivalErrorMessage = ref<ArrivalErrorMessage>(null)

  async function fetchArrivals(fetchParams: FetchArrivalParams) {
    arrivalLoading.value = true
    try {
      const { data } = await getArrivals(fetchParams)
      arrivals.value = data
    } catch (error) {
      console.log(error)
      arrivalErrorMessage.value = 'Fel: Kunde inte hämta ankomster'
    }
    arrivalLoading.value = false
  }

  return { arrivalLoading, arrivalErrorMessage, fetchArrivals, arrivals }
}
