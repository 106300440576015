<template>
  <div :class="$style.headerGroup" class="bg-blue-grey-1">
    <q-separator />
    <div class="q-ml-sm text-subtitle2"><slot></slot></div>
    <q-separator class="q-mb-sm" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TrainListHeaderGroup',
})
</script>

<style lang="scss" module>
.headerGroup {
  position: sticky;
  top: 0px;
  z-index: 99;
  background: white;
}
</style>
