
import { useTrain } from '@/compositions/useTrain'
import { useVehicle } from '@/compositions/useVehicle'
import { useRoute } from 'vue-router'
import { computed, defineComponent } from 'vue'
import TrainHeader from '@/views/train/components/TrainHeader.vue'
import VehicleActivities from './components/VehicleActivities.vue'
import VehicleDeviations from './components/deviation/VehicleDeviations.vue'

export default defineComponent({
  name: 'Vehicle',

  components: {
    TrainHeader,
    VehicleActivities,
    VehicleDeviations,
  },

  setup() {
    const { fetchTrain, trainLoading, train } = useTrain()
    const { fetchVehicle, vehicleLoading, vehicle } = useVehicle()
    const route = useRoute()

    const trainUuid =
      typeof route.params.trainUuid === 'string'
        ? route.params.trainUuid
        : 'missing'

    const vehicleUuid =
      typeof route.params.vehicleUuid === 'string'
        ? route.params.vehicleUuid
        : 'missing'

    fetchTrain(trainUuid)
    fetchVehicle(vehicleUuid)

    const loading = computed(() => {
      return (
        trainLoading.value ||
        !train.value ||
        vehicleLoading.value ||
        !vehicle.value
      )
    })

    return {
      loading,
      train,
      vehicle,
    }
  },
})
