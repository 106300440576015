import { getVehicles, getVehicle } from '@/api/vehicle.api'
import { Vehicle } from '@/types/vehicle'
import { ref, Ref } from 'vue'

type VehicleErrorMessage = string | null

interface UseVehicle {
  vehicleLoading: Ref<boolean>
  vehicleErrorMessage: Ref<VehicleErrorMessage>
  fetchVehicles: () => Promise<void>
  fetchVehicle: (vehicleUuid: string) => Promise<void>
  vehicles: Ref<Vehicles>
  vehicle: Ref<Vehicle | null>
}

type Vehicles = Vehicle[] | null

const vehicle = ref<Vehicle | null>(null)

export const useVehicle = (): UseVehicle => {
  const vehicles = ref<Vehicles>(null)

  const vehicleLoading = ref(false)
  const vehicleErrorMessage = ref<VehicleErrorMessage>(null)

  async function fetchVehicles() {
    vehicleLoading.value = true
    try {
      const { data } = await getVehicles()
      vehicles.value = data
    } catch (error) {
      console.log(error)
      vehicleErrorMessage.value = 'Fel: Kunde inte hämta fordonen'
    }
    vehicleLoading.value = false
  }

  async function fetchVehicle(vehicleUuid: string) {
    vehicleLoading.value = true
    try {
      const { data } = await getVehicle(vehicleUuid)
      vehicle.value = data
    } catch (error) {
      console.log(error)
      vehicleErrorMessage.value = 'Fel: Kunde inte hämta fordon'
    }
    vehicleLoading.value = false
  }

  return {
    vehicleLoading,
    vehicleErrorMessage,
    fetchVehicles,
    fetchVehicle,
    vehicles,
    vehicle,
  }
}
