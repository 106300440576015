import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { useUser } from '@/compositions/useUser'
import { useProfile } from '@/compositions/useProfile'
import SignIn from '@/views/sign-in/SignIn.vue'
import Register from '@/views/register/Register.vue'
import Arrivals from '@/views/arrivals/Arrivals.vue'
import Train from '@/views/train/Train.vue'
import Vehicle from '@/views/vehicle/Vehicle.vue'
import LayoutDefault from '@/layouts/LayoutDefault.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/register',
    alias: '/',
    name: 'register',
    component: Register,
    meta: {
      header: 'Registrera',
      layout: LayoutDefault,
    },
  },
  {
    path: '/register/:location/arrivals',
    name: 'arrivals',
    component: Arrivals,
    meta: {
      header: 'Registrera',
      layout: LayoutDefault,
    },
  },
  {
    path: '/register/train/:trainUuid',
    name: 'train',
    component: Train,
    meta: {
      header: 'Registrera',
      layout: LayoutDefault,
    },
  },
  {
    path: '/register/train/:trainUuid/vehicle/:vehicleUuid',
    name: 'vehicle',
    component: Vehicle,
    meta: {
      header: 'Registrera',
      layout: LayoutDefault,
    },
  },
  {
    path: '/sign-in',
    name: 'sign-in',
    component: SignIn,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(async (to, from) => {
  const { checkSignedIn, signOut } = useUser()
  const { fetchProfile, profile, hasPermission, showPermissionModal } =
    useProfile()
  const isSignedIn = await checkSignedIn()

  if (isSignedIn && !profile.value) {
    await fetchProfile()
  }

  if (isSignedIn && to.path !== '/sign-in' && !hasPermission('mobile.view')) {
    showPermissionModal.value = true
    profile.value = null
    signOut()
    return '/sign-in'
  }

  if (to.path === '/sign-in' && isSignedIn) {
    return from.path
  }

  if (to.path !== '/sign-in' && !isSignedIn) {
    return '/sign-in'
  }
})

export default router
