<template>
  <TrainVehicleConfirmCheckDialog
    v-model="showConfirmDialog"
    :vehicle-uuid="selectedVehicleUuid"
    :activity-uuid="selectedActivityUuid"
  />
  <TrainVehicleConfirmUnCheckDialog
    v-model="showConfirmUnregisterDialog"
    :vehicle-uuid="selectedVehicleUuid"
    :activity-uuid="selectedActivityUuid"
  />
  <div class="row no-wrap">
    <div class="col-12 q-pa-md flex justify-between items-center">
      <div class="flex column">
        <div class="text-body1 text-bold">Aktiviteter</div>
      </div>
    </div>
  </div>
  <div class="q-pa-md">
    <VehicleActivityCard
      @confirm="openConfirmDialog"
      v-for="activity in activities"
      :activity="activity"
      :key="activity.uuid"
      :train="train"
      :vehicle="vehicle"
    />
  </div>
</template>

<script lang="ts">
import TrainVehicleConfirmCheckDialog from './VehicleConfirmCheckDialog.vue'
import TrainVehicleConfirmUnCheckDialog from './VehicleConfirmUnCheckDialog.vue'
import VehicleActivityCard from './VehicleActivityCard.vue'
import { defineComponent, PropType, ref } from 'vue'

import { Train } from '@/types/train'
import { TrainActivity } from '@/types/train-activity'
import { Vehicle } from '@/types/vehicle'

export type ConfirmDialogType = 'REGISTER' | 'UNREGISTER'

export interface ConfirmDialogPayload {
  type: ConfirmDialogType
  vehicleUuid: string | null
  activityUuid: string | null
}

export default defineComponent({
  name: 'VehicleActivities',

  components: {
    VehicleActivityCard,
    TrainVehicleConfirmCheckDialog,
    TrainVehicleConfirmUnCheckDialog,
  },

  props: {
    train: Object as PropType<Train>,
    vehicle: Object as PropType<Vehicle>,
    activities: Array as PropType<TrainActivity[]>,
  },

  setup() {
    const showConfirmDialog = ref(false)
    const showConfirmUnregisterDialog = ref(false)
    const selectedVehicleUuid = ref<string | null>(null)
    const selectedActivityUuid = ref<string | null>(null)

    function openConfirmDialog(payload: ConfirmDialogPayload) {
      selectedVehicleUuid.value = payload.vehicleUuid
      selectedActivityUuid.value = payload.activityUuid

      if (payload.type === 'REGISTER') {
        showConfirmDialog.value = true
      }

      if (payload.type === 'UNREGISTER') {
        showConfirmUnregisterDialog.value = true
      }
    }

    return {
      showConfirmDialog,
      showConfirmUnregisterDialog,
      openConfirmDialog,
      selectedVehicleUuid,
      selectedActivityUuid,
    }
  },
})
</script>
