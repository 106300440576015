import { instance } from '@/services/iss-nattag-api.service'
import { AxiosResponse } from 'axios'
import { useError } from '@/compositions/useError'
import { Deviation } from '@/types/deviation'
import { Photo } from '@/types/photo'

const URL = 'deviation'

const { error, showErrorModal } = useError()

export const sendDeviationForm = (
  deviationFormBody: Deviation
): Promise<AxiosResponse<Deviation>> =>
  instance.post(`${URL}`, deviationFormBody)

export const sendDeviationPhoto = (
  formData: FormData
): Promise<AxiosResponse<Photo[]>> =>
  instance
    .post(`photo`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .catch((errorResult) => {
      error.name = 'Bilden kunde inte laddas upp!'
      error.description =
        'Avvikelsen registrerades men bilden kunde inte laddas upp'
      showErrorModal.value = true
      return errorResult
    })

export const updateDeviationForm = (
  deviationUuid: string,
  deviationFormBody: Deviation
): Promise<AxiosResponse<Deviation>> =>
  instance.put(`${URL}/${deviationUuid}`, deviationFormBody)

export const deleteDeviationPhoto = (
  deviationPhotoUuid: string
): Promise<AxiosResponse<void>> =>
  instance.delete(`/photo/${deviationPhotoUuid}`)
