import { instance } from '@/services/iss-nattag-api.service'
import { Vehicle } from '@/types/vehicle'
import { AxiosResponse } from 'axios'

const URL = 'vehicle'

export const getVehicles = (): Promise<AxiosResponse<Vehicle[]>> =>
  instance.get(`${URL}`)

export const getVehicle = (
  vehicleUuid: string
): Promise<AxiosResponse<Vehicle>> => instance.get(`${URL}/${vehicleUuid}`)
