
import { computed, defineComponent } from 'vue'
import { useTrain } from '@/compositions/useTrain'

export default defineComponent({
  name: 'VehicleConfirmUnCheckDialog',

  props: {
    modelValue: Boolean,
    vehicleUuid: String,
    activityUuid: String,
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const { unregistrateVehicle, registrationLoading } = useTrain()

    const show = computed({
      get() {
        return props.modelValue
      },

      set(value: boolean) {
        emit('update:modelValue', value)
      },
    })

    async function onConfirm() {
      const registrationBody = {
        activityUuid: props.activityUuid || 'missing',
        vehicleUuid: props.vehicleUuid || 'missing',
      }

      await unregistrateVehicle(registrationBody)

      show.value = false
    }

    return { show, onConfirm, registrationLoading }
  },
})
