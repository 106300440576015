
import { defineComponent } from 'vue'
import { useDeviationType } from '@/compositions/useDeviationType'
import { useDeviationForm } from '../../compositions/useDeviationForm'
import { required as requiredRule } from '@/common/formValidationRules'

export default defineComponent({
  name: 'VehicleDeviationCategoryInput',

  setup() {
    const { deviationTypeLoading, deviationTypes } = useDeviationType()

    const { deviationForm } = useDeviationForm()

    return {
      deviationTypeLoading,
      deviationTypes,
      deviationForm,
      requiredRule,
    }
  },
})
