<template>
  <TrainVehicleDeviationImageFullScreenDialog
    v-model="showFullscreenImage"
    :src="deviationPhotoSrc"
  />
  <input
    ref="captureImageRef"
    :class="$style.inputImage"
    type="file"
    accept="image/*"
    capture
  />
  <transition
    enter-active-class="animated animate__zoomIn"
    leave-active-class="animated animate__zoomOut"
    mode="out-in"
    :duration="70"
  >
    <q-btn
      @click="takePhoto"
      v-if="!deviationPhotoSrc"
      padding="xl"
      color="white"
      text-color="primary"
      icon="mdi-camera-plus"
    >
    </q-btn>
    <q-img
      v-else
      @click="showFullscreenImage = true"
      class="rounded-borders shadow-5"
      :src="deviationPhotoSrc"
      style="height: 130px; width: 100%"
      fit="cover"
    >
      <div class="absolute-bottom flex items-end" style="padding: 0">
        <q-btn
          size="sm"
          @click.stop="removePhoto"
          flat
          round
          icon="mdi-delete-forever"
        />
        <q-space />
        <q-btn
          size="sm"
          flat
          round
          icon="mdi-camera-retake"
          @click.stop="takePhoto"
        />
      </div>
    </q-img>
  </transition>
</template>

<script lang="ts">
import TrainVehicleDeviationImageFullScreenDialog from './VehicleDeviationImageFullScreenDialog.vue'
import { defineComponent, ref, onMounted } from 'vue'
import { useDeviationForm } from '../../compositions/useDeviationForm'

export default defineComponent({
  name: 'VehicleDeviationPhotoInput',

  components: {
    TrainVehicleDeviationImageFullScreenDialog,
  },

  setup() {
    const showFullscreenImage = ref(false)
    const captureImageRef = ref<HTMLInputElement | null>(null)
    const { deviationPhotoSrc, deviationPhotoFile } = useDeviationForm()

    onMounted(() => {
      setTimeout(() => {
        if (!captureImageRef.value) return
        captureImageRef.value.addEventListener('change', (e) => {
          const files = (e.target as HTMLInputElement).files
          const file = files?.[0]
          if (file) {
            deviationPhotoFile.value = file
            deviationPhotoSrc.value = URL.createObjectURL(file)
          }
        })
      }, 0)
    })

    async function removePhoto() {
      deviationPhotoFile.value = null
      deviationPhotoSrc.value = null
    }

    function takePhoto() {
      if (captureImageRef?.value?.value) {
        captureImageRef.value.value = ''
      }

      if (!captureImageRef.value) return

      captureImageRef.value.click()
    }

    return {
      showFullscreenImage,
      captureImageRef,
      deviationPhotoSrc,
      removePhoto,
      takePhoto,
    }
  },
})
</script>

<style lang="scss" module>
.inputImage {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
</style>
