<template>
  <q-dialog v-model="showDeviationFormModal" position="bottom">
    <VehicleDeviationFormDialog
      :vehicle="vehicle"
      :train="train"
      @close="showDeviationFormModal = false"
      v-if="showDeviationFormModal"
    />
  </q-dialog>
  <div class="row no-wrap">
    <div class="col-12 q-pa-md flex justify-between items-center">
      <div class="flex column">
        <div class="text-body1 text-bold">Avvikelser</div>
        <div class="text-caption text-grey-7">
          Antal: {{ vehicle.deviations.length }}
        </div>
      </div>

      <q-btn
        color="white"
        text-color="negative"
        icon-right="mdi-plus-circle-outline"
        label="Lägg till"
        @click="openAddDeviation"
      />
    </div>
  </div>
  <div
    v-if="deviationTypeLoading"
    class="flex items-center justify-center"
    style="min-height: inherit"
  >
    <q-spinner color="primary" size="3em" :thickness="2" />
  </div>
  <VehicleDeviationScrollList
    v-else
    :vehicle-uuid="vehicle.uuid"
    :deviations="vehicle.deviations"
  />
</template>

<script lang="ts">
import VehicleDeviationFormDialog from './VehicleDeviationFormDialog.vue'
import VehicleDeviationScrollList from './VehicleDeviationScrollList.vue'
import { defineComponent, PropType } from 'vue'
import { useDeviationType } from '@/compositions/useDeviationType'
import { useDeviation } from '../../compositions/useDeviation'
import { useDeviationForm } from '../../compositions/useDeviationForm'
import { useVehicleTypePlace } from '@/compositions/useVehicleTypePlace'
import { Train } from '@/types/train'
import { Vehicle } from '@/types/vehicle'

export default defineComponent({
  name: 'VehicleDeviations',

  components: {
    VehicleDeviationFormDialog,
    VehicleDeviationScrollList,
  },

  props: {
    train: Object as PropType<Train>,
    vehicle: Object as PropType<Vehicle>,
  },

  setup() {
    const { resetDeviationForm } = useDeviationForm()
    const { showDeviationFormModal, deviationEditMode } = useDeviation()
    const { fetchDeviationTypes, deviationTypeLoading } = useDeviationType()
    const { fetchVehicleTypePlaces } = useVehicleTypePlace()
    fetchDeviationTypes()
    fetchVehicleTypePlaces()

    function openAddDeviation() {
      resetDeviationForm()
      deviationEditMode.value = false
      showDeviationFormModal.value = true
    }

    return { deviationTypeLoading, showDeviationFormModal, openAddDeviation }
  },
})
</script>
