<template>
  <q-select
    dense
    :loading="deviationTypeLoading"
    :options="deviationTypes"
    v-model="deviationForm.deviationTypeUuid"
    emit-value
    map-options
    option-value="uuid"
    option-label="name"
    label="Kategori"
    :rules="[requiredRule]"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useDeviationType } from '@/compositions/useDeviationType'
import { useDeviationForm } from '../../compositions/useDeviationForm'
import { required as requiredRule } from '@/common/formValidationRules'

export default defineComponent({
  name: 'VehicleDeviationCategoryInput',

  setup() {
    const { deviationTypeLoading, deviationTypes } = useDeviationType()

    const { deviationForm } = useDeviationForm()

    return {
      deviationTypeLoading,
      deviationTypes,
      deviationForm,
      requiredRule,
    }
  },
})
</script>
