
import { Vehicle } from '@/types/vehicle'
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import { defineComponent, PropType, ref } from 'vue'
import VehicleDeviationMap from './VehicleDeviationMap.vue'

export default defineComponent({
  name: 'VehicleDeviationSlider',

  components: {
    VehicleDeviationMap,
  },

  props: {
    vehicle: {
      type: Object as PropType<Vehicle>,
    },
  },

  setup() {
    const refScrollArea = ref<QScrollArea | null>(null)

    return {
      refScrollArea,
    }
  },
})
