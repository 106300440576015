<template>
  <q-scroll-area ref="deviationScrollRef" style="width: 100%">
    <transition
      enter-active-class="animated animate__fadeInLeft"
      leave-active-class="animated animate__fadeOutRight"
      mode="out-in"
      :duration="70"
    >
      <div class="q-pa-md" :key="vehicleUuid" v-if="sortedDeviations.length">
        <VehicleDeviationCard
          :deviation="deviation"
          v-for="deviation in sortedDeviations"
          :key="deviation.uuid"
        />
      </div>
      <div
        v-else
        class="
          flex
          items-center
          justify-center
          q-mt-lg
          text-grey-8 text-subtitle2 text-weight-light
        "
      >
        <q-icon name="mdi-emoticon-happy-outline" class="q-mr-sm" />
        Inga avvikelser
      </div>
    </transition>
  </q-scroll-area>
</template>

<script lang="ts">
import { QScrollArea } from 'quasar'
import { defineComponent, onMounted, PropType, ref, computed } from 'vue'
import VehicleDeviationCard from './VehicleDeviationCard.vue'
import orderBy from 'lodash.orderby'
import { Deviation } from '@/types/deviation'

export default defineComponent({
  name: 'VehicleDeviationScrollList',

  components: {
    VehicleDeviationCard,
  },

  props: {
    vehicleUuid: String,
    deviations: Array as PropType<Deviation[]>,
  },

  setup(props) {
    const deviationScrollRef = ref<QScrollArea | null>(null)

    onMounted(() => {
      setTimeout(() => {
        if (!deviationScrollRef.value) return

        const box = deviationScrollRef.value.$el.getBoundingClientRect()
        deviationScrollRef.value.$el.style.height = `${
          window.innerHeight - box.top
        }px`
      }, 0)
    })

    const sortedDeviations = computed(() => {
      return orderBy(props.deviations, ['created'], ['desc'])
    })

    return { deviationScrollRef, sortedDeviations }
  },
})
</script>
