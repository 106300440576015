
import VehicleDeviationFormDialog from './VehicleDeviationFormDialog.vue'
import VehicleDeviationScrollList from './VehicleDeviationScrollList.vue'
import { defineComponent, PropType } from 'vue'
import { useDeviationType } from '@/compositions/useDeviationType'
import { useDeviation } from '../../compositions/useDeviation'
import { useDeviationForm } from '../../compositions/useDeviationForm'
import { useVehicleTypePlace } from '@/compositions/useVehicleTypePlace'
import { Train } from '@/types/train'
import { Vehicle } from '@/types/vehicle'

export default defineComponent({
  name: 'VehicleDeviations',

  components: {
    VehicleDeviationFormDialog,
    VehicleDeviationScrollList,
  },

  props: {
    train: Object as PropType<Train>,
    vehicle: Object as PropType<Vehicle>,
  },

  setup() {
    const { resetDeviationForm } = useDeviationForm()
    const { showDeviationFormModal, deviationEditMode } = useDeviation()
    const { fetchDeviationTypes, deviationTypeLoading } = useDeviationType()
    const { fetchVehicleTypePlaces } = useVehicleTypePlace()
    fetchDeviationTypes()
    fetchVehicleTypePlaces()

    function openAddDeviation() {
      resetDeviationForm()
      deviationEditMode.value = false
      showDeviationFormModal.value = true
    }

    return { deviationTypeLoading, showDeviationFormModal, openAddDeviation }
  },
})
