import { render } from "./SignInForm.vue?vue&type=template&id=b083e5e6"
import script from "./SignInForm.vue?vue&type=script&lang=ts"
export * from "./SignInForm.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSlideTransition from 'quasar/src/components/slide-transition/QSlideTransition.js';
import QBanner from 'quasar/src/components/banner/QBanner.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QForm,QInput,QIcon,QSlideTransition,QBanner,QBtn});
