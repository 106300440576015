
import { computed, defineComponent, ref } from 'vue'
import TrainHeader from './components/TrainHeader.vue'
import TrainVehicleCard from './components/TrainVehicleCard.vue'
import { useTrain } from '@/compositions/useTrain'
import { useVehicle } from '@/compositions/useVehicle'
import { useRoute } from 'vue-router'
import TrainListHeaderGroup from './components/TrainListHeaderGroup.vue'
import { Vehicle } from '@/types/vehicle'

export default defineComponent({
  name: 'Train',

  components: {
    TrainHeader,
    TrainVehicleCard,
    TrainListHeaderGroup,
  },

  setup() {
    const { fetchTrain, trainLoading, train } = useTrain()
    const { fetchVehicles, vehicleLoading, vehicles } = useVehicle()
    const route = useRoute()

    const vehicleSearch = ref('')

    const trainUuid =
      typeof route.params.trainUuid === 'string'
        ? route.params.trainUuid
        : 'missing'

    fetchTrain(trainUuid)
    fetchVehicles()

    const loading = computed(() => {
      return (
        trainLoading.value ||
        !train.value ||
        !vehicles.value ||
        vehicleLoading.value
      )
    })

    function getVehicleFilterText(vehicle: Vehicle) {
      return `${vehicle.vehicleTypeName} ${vehicle.name} ${vehicle.description}`.toLowerCase()
    }

    function isCompleted(vehicle: Vehicle) {
      return train.value?.activities.every((activity) => {
        return activity.vehicles.some(
          (activityVehicle) => activityVehicle.vehicleUuid === vehicle.uuid
        )
      })
    }

    const filteredCompleteVehicles = computed(() => {
      return (vehicles.value || []).filter(isCompleted).filter((vehicle) => {
        const vehicleText = getVehicleFilterText(vehicle)
        return (
          !vehicleSearch.value.length ||
          vehicleText.includes(vehicleSearch.value.toLowerCase())
        )
      })
    })

    const filteredNotCompleteVehicles = computed(() => {
      return (vehicles.value || [])
        .filter((vehicle) => !isCompleted(vehicle))
        .filter((vehicle) => {
          const vehicleText = getVehicleFilterText(vehicle)
          return (
            !vehicleSearch.value.length ||
            vehicleText.includes(vehicleSearch.value.toLowerCase())
          )
        })
    })

    return {
      loading,
      vehicles,
      train,
      vehicleSearch,
      filteredCompleteVehicles,
      filteredNotCompleteVehicles,
    }
  },
})
