
import { Train } from '@/types/train'
import { Vehicle } from '@/types/vehicle'
import { PropType, defineComponent } from 'vue'
import { useDeviation } from '../../compositions/useDeviation'

export default defineComponent({
  name: 'VehicleDeviationFormDialogHeader',

  props: {
    train: Object as PropType<Train>,
    vehicle: Object as PropType<Vehicle>,
  },

  setup() {
    const { showDeviationFormModal } = useDeviation()
    function onClose() {
      showDeviationFormModal.value = false
    }

    return { onClose }
  },
})
