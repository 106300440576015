
import AppErrorModal from '@/components/AppErrorModal.vue'
import AppPermissionModal from '@/components/AppPermissionModal.vue'
import { ref, defineComponent } from 'vue'
import { useError } from '@/compositions/useError'
import { useProfile } from '@/compositions/useProfile'

export default defineComponent({
  name: 'App',

  components: { AppErrorModal, AppPermissionModal },

  setup() {
    const { showErrorModal } = useError()
    const { showPermissionModal } = useProfile()
    return {
      showErrorModal,
      showPermissionModal,
      leftDrawerOpen: ref(false),
    }
  },
})
