import { Ref, ref, reactive } from 'vue'

interface Error {
  name: string | null
  description: string | null
}

interface UseError {
  error: Error
  showErrorModal: Ref<boolean>
}

const error = reactive<Error>({
  name: null,
  description: null,
})
const showErrorModal = ref(false)

export const useError = (): UseError => {
  return { error, showErrorModal }
}
