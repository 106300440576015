<template>
  <q-card style="max-width: 350px" class="full-width">
    <q-card-section>
      <div class="text-subtitle1 text-bold flex items-center">
        <q-icon name="mdi-check-circle-outline" class="q-mr-sm" />
        Rapportera utförd avvikelse
      </div>
    </q-card-section>

    <q-card-section class="q-pt-none text-body2 text-grey-8">
      <p>Är du säker på att du vill rapportera avvikelsen som utförd?</p>
    </q-card-section>

    <q-card-section class="q-pt-none">
      <q-input
        type="textarea"
        rows="2"
        :disabled="updateDeviationLoading"
        filled
        v-model="completedDescription"
        label="Beskrivning"
        placeholder="Beskriv utförandet..."
      />
    </q-card-section>

    <q-card-actions align="right" class="text-primary">
      <q-btn
        flat
        label="Avbryt"
        @click="$emit('close')"
        :disabled="updateDeviationLoading"
      />
      <q-btn
        color="white"
        text-color="positive"
        label="Bekräfta"
        :loading="updateDeviationLoading"
        @click="onConfirm"
      />
    </q-card-actions>
  </q-card>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useDeviationForm } from '../../compositions/useDeviationForm'
import { useDeviation } from '../../compositions/useDeviation'
import { Deviation } from '@/types/deviation'

export default defineComponent({
  name: 'VehicleDeviationCheckDialog',

  setup() {
    const completedDescription = ref('')
    const { selectedDeviation, showDeviationFormModal, selectedDeviationForm } =
      useDeviation()

    const { completeDeviation, updateDeviationLoading } = useDeviationForm()

    async function onConfirm() {
      if (selectedDeviation?.value?.uuid && selectedDeviationForm.value) {
        const completedDeviationBody: Deviation = {
          ...selectedDeviationForm.value,
          completed: new Date(),
          completedDescription: completedDescription.value || null,
        }
        await completeDeviation(
          selectedDeviation.value.uuid,
          completedDeviationBody
        )
      }

      showDeviationFormModal.value = false
    }

    return { completedDescription, onConfirm, updateDeviationLoading }
  },
})
</script>
