import { getDeviationTypes } from '@/api/deviation-type.api'
import { DeviationType } from '@/types/deviation-type'
import { ref, Ref } from 'vue'

type DevitionTypeErrorMessage = string | null

interface UseDeviationType {
  deviationTypeLoading: Ref<boolean>
  deviationTypeErrorMessage: Ref<DevitionTypeErrorMessage>
  fetchDeviationTypes: () => Promise<void>
  deviationTypes: Ref<DeviationType[] | []>
}

const deviationTypes = ref<DeviationType[] | []>([])

export const useDeviationType = (): UseDeviationType => {
  const deviationTypeLoading = ref(false)
  const deviationTypeErrorMessage = ref<DevitionTypeErrorMessage>(null)

  async function fetchDeviationTypes() {
    deviationTypeLoading.value = true
    try {
      const { data } = await getDeviationTypes()
      deviationTypes.value = data
    } catch (error) {
      console.log(error)
      deviationTypeErrorMessage.value =
        'Fel: Kunde inte hämta avvikelse typerna'
    }
    deviationTypeLoading.value = false
  }

  return {
    deviationTypeLoading,
    deviationTypeErrorMessage,
    fetchDeviationTypes,
    deviationTypes,
  }
}
