
import ArrivalCard from './components/ArrivalCard.vue'
import { useArrival } from '@/compositions/useArrival'
import { defineComponent, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { format } from 'date-fns'

const formatDate = (date: Date) => format(date, 'yyyy-MM-dd')

export default defineComponent({
  name: 'Arrivals',

  components: {
    ArrivalCard,
  },

  setup() {
    const route = useRoute()
    const { fetchArrivals, arrivalLoading, arrivals } = useArrival()
    const today = new Date()

    const selectedDate = ref(formatDate(today))
    const showDateDialog = ref(false)

    const location =
      typeof route.params.location === 'string'
        ? route.params.location
        : 'missing'

    watch(
      selectedDate,
      (date) => {
        fetchArrivals({
          location,
          trafficDates: [date],
        })
      },
      {
        immediate: true,
      }
    )

    return {
      showDateDialog,
      selectedDate,
      arrivalLoading,
      arrivals,
    }
  },
})
