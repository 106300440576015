<template>
  <q-card class="q-mt-sm" v-ripple @click="goToVehicle">
    <q-card-section>
      <div class="row items-center no-wrap">
        <div class="col">
          <div class="text-body1">
            {{ vehicle.vehicleTypeName }} - {{ vehicle.name }}
          </div>
          <div class="text-body2 text-blue-grey-5">
            {{ vehicle.description }}
          </div>
        </div>

        <div class="col-auto">
          <q-btn color="grey-7" round flat icon="mdi-chevron-right" />
        </div>
      </div>
    </q-card-section>
  </q-card>
</template>

<script lang="ts">
import { Vehicle } from '@/types/vehicle'
import { defineComponent, PropType } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'TrainVehicleCard',

  props: {
    trainUuid: String,
    vehicle: Object as PropType<Vehicle>,
  },

  setup(props) {
    const router = useRouter()

    function goToVehicle() {
      router.push(
        `/register/train/${props.trainUuid}/vehicle/${props?.vehicle?.uuid}`
      )
    }

    return {
      goToVehicle,
    }
  },
})
</script>
