
import { computed, defineComponent, PropType } from 'vue'
import { format } from 'date-fns'
import { useRouter } from 'vue-router'
import { Arrival } from '@/types/arrival'

export default defineComponent({
  name: 'ArrivalCard',

  props: {
    arrival: {
      type: Object as PropType<Arrival>,
      required: true,
    },
  },

  setup(props) {
    const router = useRouter()

    const arrivalTime = format(new Date(props.arrival.arrival), 'HH:mm')

    function onOpenTrain() {
      router.push(`/register/train/${props.arrival.uuid}`)
    }

    const delayColorStyle = computed(() => {
      if (
        !props.arrival ||
        props.arrival.latestDelay === null ||
        props.arrival.latestDelay === 0
      )
        return {
          color: 'black',
        }
      return {
        color: props.arrival?.latestDelay < 0 ? 'green' : 'red',
      }
    })

    return {
      status,
      arrivalTime,
      onOpenTrain,
      delayColorStyle,
    }
  },
})
