<template>
  <div class="q-px-md">
    <q-input borderless :model-value="selectedDate" readonly>
      <template v-slot:default>
        <q-popup-proxy
          transition-show="scale"
          transition-hide="scale"
          v-model="showDateDialog"
        >
          <q-date
            mask="YYYY-MM-DD"
            v-model="selectedDate"
            @update:model-value="showDateDialog = false"
          />
        </q-popup-proxy>
      </template>
      <template v-slot:prepend>
        <q-icon name="mdi-calendar" />
      </template>
    </q-input>
  </div>

  <q-separator />

  <q-scroll-area style="height: calc(100vh - 50px - 56px)">
    <transition
      enter-active-class="animated animate__fadeIn"
      leave-active-class="animated animate__fadeOut"
      mode="out-in"
      :duration="140"
    >
      <div
        v-if="arrivalLoading || !arrivals"
        class="flex full-height items-center justify-center"
        style="min-height: inherit"
      >
        <q-spinner color="primary" size="3em" :thickness="2" />
      </div>
      <div
        v-else-if="!arrivals.length"
        class="
          q-pa-sm
          fit
          flex
          justify-center
          items-center
          text-grey-7 text-body1
        "
      >
        <q-icon name="mdi-information-outline" class="q-mr-sm" />
        Ingen data
      </div>
      <div v-else class="q-pa-sm fit">
        <ArrivalCard
          v-for="(arrival, $i) in arrivals"
          :arrival="arrival"
          :key="$i"
        />
      </div>
    </transition>
  </q-scroll-area>
</template>

<script lang="ts">
import ArrivalCard from './components/ArrivalCard.vue'
import { useArrival } from '@/compositions/useArrival'
import { defineComponent, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { format } from 'date-fns'

const formatDate = (date: Date) => format(date, 'yyyy-MM-dd')

export default defineComponent({
  name: 'Arrivals',

  components: {
    ArrivalCard,
  },

  setup() {
    const route = useRoute()
    const { fetchArrivals, arrivalLoading, arrivals } = useArrival()
    const today = new Date()

    const selectedDate = ref(formatDate(today))
    const showDateDialog = ref(false)

    const location =
      typeof route.params.location === 'string'
        ? route.params.location
        : 'missing'

    watch(
      selectedDate,
      (date) => {
        fetchArrivals({
          location,
          trafficDates: [date],
        })
      },
      {
        immediate: true,
      }
    )

    return {
      showDateDialog,
      selectedDate,
      arrivalLoading,
      arrivals,
    }
  },
})
</script>
