<template>
  <transition
    enter-active-class="animated animate__fadeIn"
    leave-active-class="animated animate__fadeOut"
    mode="out-in"
    :duration="140"
  >
    <div
      v-if="loading"
      class="flex full-height items-center justify-center"
      style="min-height: inherit"
    >
      <q-spinner color="primary" size="3em" :thickness="2" />
    </div>
    <div v-else>
      <TrainHeader :train="train">
        <q-separator vertical class="q-ml-md q-mr-md" />
        <div>
          <div class="text-subtitle2">
            {{ vehicle.vehicleTypeName }} - {{ vehicle.name }}
          </div>
          <div class="text-body2 text-grey-6">{{ vehicle.description }}</div>
        </div>
      </TrainHeader>
      <VehicleActivities
        :train="train"
        :vehicle="vehicle"
        :activities="train.activities"
      />
      <q-separator />
      <VehicleDeviations :train="train" :vehicle="vehicle" />
    </div>
  </transition>
</template>

<script lang="ts">
import { useTrain } from '@/compositions/useTrain'
import { useVehicle } from '@/compositions/useVehicle'
import { useRoute } from 'vue-router'
import { computed, defineComponent } from 'vue'
import TrainHeader from '@/views/train/components/TrainHeader.vue'
import VehicleActivities from './components/VehicleActivities.vue'
import VehicleDeviations from './components/deviation/VehicleDeviations.vue'

export default defineComponent({
  name: 'Vehicle',

  components: {
    TrainHeader,
    VehicleActivities,
    VehicleDeviations,
  },

  setup() {
    const { fetchTrain, trainLoading, train } = useTrain()
    const { fetchVehicle, vehicleLoading, vehicle } = useVehicle()
    const route = useRoute()

    const trainUuid =
      typeof route.params.trainUuid === 'string'
        ? route.params.trainUuid
        : 'missing'

    const vehicleUuid =
      typeof route.params.vehicleUuid === 'string'
        ? route.params.vehicleUuid
        : 'missing'

    fetchTrain(trainUuid)
    fetchVehicle(vehicleUuid)

    const loading = computed(() => {
      return (
        trainLoading.value ||
        !train.value ||
        vehicleLoading.value ||
        !vehicle.value
      )
    })

    return {
      loading,
      train,
      vehicle,
    }
  },
})
</script>
