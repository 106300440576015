<template>
  <div>
    <q-header class="bg-white text-black">
      <q-toolbar>
        <div class="flex justify-between full-width items-center">
          <q-btn
            flat
            dense
            round
            icon="mdi-arrow-left"
            v-if="showBackButton"
            @click="goBack"
          />
          <AppLogo @click="goToStartPage" v-else />
          <div>
            <q-toolbar-title class="text-subtitle1">{{
              meta.header
            }}</q-toolbar-title>
          </div>
          <q-btn
            flat
            @click="drawerRight = !drawerRight"
            round
            dense
            icon="mdi-menu-open"
          />
        </div>
      </q-toolbar>
    </q-header>

    <q-drawer
      side="right"
      v-model="drawerRight"
      bordered
      :width="220"
      :breakpoint="500"
      content-class="bg-grey-3"
    >
      <AppDrawer />
    </q-drawer>

    <q-page-container>
      <q-page>
        <slot></slot>
      </q-page>
    </q-page-container>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import AppLogo from '@/components/AppLogo.vue'
import AppDrawer from '@/components/AppDrawer.vue'
import { useRouter, useRoute } from 'vue-router'

export default defineComponent({
  name: 'LayoutDefault',

  components: {
    AppLogo,
    AppDrawer,
  },

  props: {
    meta: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const router = useRouter()
    const route = useRoute()
    const drawerRight = ref(false)

    function goToStartPage() {
      router.push('/')
    }

    function goBack() {
      router.back()
    }

    const showBackButton = computed(() => {
      return route.path !== '/register'
    })

    return { drawerRight, goToStartPage, showBackButton, goBack }
  },
})
</script>
