import { ref, Ref } from 'vue'
import {
  sendDeviationForm,
  updateDeviationForm,
  sendDeviationPhoto,
  deleteDeviationPhoto,
} from '@/api/deviation.api'

import { useDeviation } from './useDeviation'
import { useVehicle } from '@/compositions/useVehicle'
import { Deviation } from '@/types/deviation'
import { Photo } from '@/types/photo'

interface UseDeviationForm {
  deviationForm: Ref<Deviation>
  deviationPhotoSrc: Ref<string | null>
  deviationPhotoFile: Ref<File | null>
  resetDeviationForm: () => void
  createDeviation: () => Promise<void>
  createDeviationLoading: Ref<boolean>
  setDeviationForm: (deviationForm: Deviation) => void
  updateDeviationLoading: Ref<boolean>
  updateDeviation: (
    deviationUuid: string,
    deviationUpdateForm: Deviation
  ) => Promise<void>
  completeDeviation: (
    deviationUuid: string,
    deviationUpdateForm: Deviation
  ) => Promise<void>
}

const createDeviationForm = () => ({
  uuid: '',
  deviationTypeUuid: '',
  vehicleUuid: '',
  vehicleTypePlaceUuid: '',
  description: null,
})

const deviationForm = ref<Deviation>(createDeviationForm())
const deviationPhotoSrc = ref<string | null>(null)
const deviationPhotoFile = ref<File | null>(null)

export const useDeviationForm = (): UseDeviationForm => {
  const { selectedPhotoSrc, selectedDeviation } = useDeviation()
  const { vehicle } = useVehicle()
  const createDeviationLoading = ref(false)
  const updateDeviationLoading = ref(false)

  function resetDeviationForm() {
    deviationPhotoSrc.value = null
    deviationPhotoFile.value = null
    const resetedForm = createDeviationForm()

    deviationForm.value = resetedForm
  }

  function setDeviationForm(_deviationForm: Deviation) {
    deviationForm.value = _deviationForm
  }

  function updateDeviationState(data: Deviation) {
    if (vehicle.value) {
      vehicle.value.deviations = vehicle.value.deviations.map((deviation) => {
        if (deviation.uuid === data.uuid) {
          if (selectedPhotoSrc.value === deviationPhotoSrc.value) {
            return {
              ...data,
              photos: deviation.photos,
            }
          }
          return data
        }
        return deviation
      })

      return data
    }

    return data
  }

  function removeDeviationState(deviationUuid: string) {
    if (vehicle.value) {
      vehicle.value.deviations = vehicle.value.deviations.filter(
        (deviation) => deviation.uuid !== deviationUuid
      )
    }
  }

  function addDeviationState(data: Deviation) {
    if (vehicle.value) {
      vehicle.value.deviations.push(data)
      return data
    }

    return data
  }

  function transformDevitionWithPhoto(
    deviation: Deviation,
    photos: Photo[]
  ): Deviation {
    return { ...deviation, photos: [photos[photos.length - 1]] }
  }

  async function createDeviation() {
    createDeviationLoading.value = true
    await new Promise((resolve) => {
      sendDeviationForm(deviationForm.value)
        .then(({ data }) => data)
        .then(addDeviationState)
        .then((deviation) => {
          if (!deviationPhotoFile.value || !deviationForm.value.uuid)
            return deviation
          const photoFormData = new FormData()
          photoFormData.append('photos[]', deviationPhotoFile.value)
          photoFormData.append('deviationUuid', deviationForm.value.uuid)
          return sendDeviationPhoto(photoFormData).then(({ data: photos }) =>
            transformDevitionWithPhoto(deviation, photos)
          )
        })
        .then(updateDeviationState)
        .catch((error) => {
          console.log(error)
          resolve([null, error])
        })
        .finally(() => {
          createDeviationLoading.value = false
          resolve([true, null])
        })
    })
  }

  async function updateDeviation(
    deviationUuid: string,
    deviationUpdateForm: Deviation
  ) {
    updateDeviationLoading.value = true
    await new Promise((resolve) => {
      updateDeviationForm(deviationUuid, deviationUpdateForm)
        .then(({ data }) => data)
        .then((deviation) => {
          if (
            selectedPhotoSrc.value === deviationPhotoSrc.value ||
            !deviationPhotoFile.value
          ) {
            return { ...deviation, photos: [] }
          }
          const photoFormData = new FormData()
          photoFormData.append('photos[]', deviationPhotoFile.value)
          photoFormData.append('deviationUuid', deviationUuid)
          return sendDeviationPhoto(photoFormData).then(({ data: photos }) =>
            transformDevitionWithPhoto(deviation, photos)
          )
        })
        .then((data) => {
          if (deviationPhotoSrc.value || !selectedDeviation.value) return data
          const deviationPhotoUuid = selectedDeviation.value.photos?.[0]
          if (!deviationPhotoUuid) return data
          return deleteDeviationPhoto(deviationPhotoUuid.uuid).then(() => data)
        })
        .then(updateDeviationState)
        .catch((error) => {
          console.log(error)
          resolve([null, error])
        })
        .finally(() => {
          updateDeviationLoading.value = false
          resolve([true, null])
        })
    })
  }

  async function completeDeviation(
    deviationUuid: string,
    completedDeviationForm: Deviation
  ) {
    updateDeviationLoading.value = true
    await new Promise((resolve) => {
      updateDeviationForm(deviationUuid, completedDeviationForm)
        .then(() => removeDeviationState(deviationUuid))
        .catch((error) => {
          console.log(error)
          resolve([null, error])
        })
        .finally(() => {
          updateDeviationLoading.value = false
          resolve([true, null])
        })
    })
  }

  return {
    deviationForm,
    deviationPhotoSrc,
    deviationPhotoFile,
    resetDeviationForm,
    createDeviation,
    createDeviationLoading,
    setDeviationForm,
    updateDeviationLoading,
    updateDeviation,
    completeDeviation,
  }
}
