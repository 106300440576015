import { Deviation } from '@/types/deviation'
import { ref, Ref } from 'vue'

interface UseDeviation {
  selectedDeviationForm: Ref<Deviation | null>
  selectedDeviation: Ref<Deviation | null>
  deviationEditMode: Ref<boolean>
  showDeviationFormModal: Ref<boolean>
  selectedPhotoSrc: Ref<string | null>
}

const selectedDeviation = ref<Deviation | null>(null)
const selectedDeviationForm = ref<Deviation | null>(null)
const selectedPhotoSrc = ref<string | null>(null)
const deviationEditMode = ref(false)
const showDeviationFormModal = ref(false)

export const useDeviation = (): UseDeviation => {
  return {
    selectedDeviationForm,
    deviationEditMode,
    showDeviationFormModal,
    selectedPhotoSrc,
    selectedDeviation,
  }
}
