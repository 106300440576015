import { render } from "./ArrivalCard.vue?vue&type=template&id=c9558ac4"
import script from "./ArrivalCard.vue?vue&type=script&lang=ts"
export * from "./ArrivalCard.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QSeparator,QIcon,QBtn});qInstall(script, 'directives', {Ripple});
