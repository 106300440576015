import { render } from "./App.vue?vue&type=template&id=156aafda"
import script from "./App.vue?vue&type=script&lang=ts"
export * from "./App.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import qInstall from "../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QLayout,QDialog});
