<template>
  <q-dialog v-model="show" position="bottom">
    <q-card flat>
      <q-card-section class="row items-center no-wrap">
        <div>
          <div class="text-weight-bold text-body1 flex items-center">
            <q-icon name="mdi-check-circle-outline" class="q-mr-sm" />Bekräfta
            uppfört
          </div>
          <div class="text-grey">
            Är du säker på att du vill bekräfta vagnen som utförd?
          </div>
        </div>
      </q-card-section>
      <q-card-actions>
        <q-btn
          :disabled="registrationLoading"
          class="q-ml-auto"
          label="Avbryt"
          @click="show = false"
        />
        <q-btn
          class="q-ml-sm"
          text-color="positive"
          label="Bekräfta"
          :loading="registrationLoading"
          @click="onConfirm"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { useTrain } from '@/compositions/useTrain'

export default defineComponent({
  name: 'VehicleConfirmCheckDialog',

  props: {
    modelValue: Boolean,
    vehicleUuid: String,
    activityUuid: String,
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const { registrateVehicle, registrationLoading } = useTrain()

    const show = computed({
      get() {
        return props.modelValue
      },

      set(value: boolean) {
        emit('update:modelValue', value)
      },
    })

    async function onConfirm() {
      const registrationBody = {
        activityUuid: props.activityUuid || 'missing',
        vehicleUuid: props.vehicleUuid || 'missing',
      }

      await registrateVehicle(registrationBody)
      show.value = false
    }

    return { show, onConfirm, registrationLoading }
  },
})
</script>
