import { render } from "./VehicleDeviationPhotoInput.vue?vue&type=template&id=cb60db0a"
import script from "./VehicleDeviationPhotoInput.vue?vue&type=script&lang=ts"
export * from "./VehicleDeviationPhotoInput.vue?vue&type=script&lang=ts"

const cssModules = script.__cssModules = {}
import style0 from "./VehicleDeviationPhotoInput.vue?vue&type=style&index=0&id=cb60db0a&lang=scss&module=true"
cssModules["$style"] = style0
script.render = render

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QImg,QSpace});
