<template>
  <q-card class="q-mb-sm q-pa-xs" square v-ripple @click="onOpenTrain">
    <q-card-section horizontal>
      <q-card-section class="q-pa-xs q-pl-sm q-mr-sm" style="width: 100px">
        <div class="column">
          <div class="text-caption text-grey-8" style="line-height: 1">Tåg</div>
          <div class="text-body2 text-weight-bold q-mt-none q-mb-xs">
            {{ arrival.train }}
          </div>
        </div>
      </q-card-section>

      <q-separator vertical />

      <q-card-section class="full-width q-pa-xs q-pl-md">
        <div class="row">
          <div class="flex no-wrap">
            <div class="column">
              <div class="text-caption text-grey-8" style="line-height: 1">
                Plats
              </div>
              <div class="text-body2 text-weight-bold q-mt-none q-mb-xs">
                {{ arrival.place }}
              </div>
            </div>
            <div class="self-end q-pb-xs q-pr-sm">
              <q-icon color="grey-7" name="mdi-arrow-right" />
            </div>
            <div class="column">
              <div class="text-caption text-grey-8" style="line-height: 1">
                Ank.
              </div>
              <div class="text-body2 text-weight-bold q-mt-none q-mb-xs">
                {{ arrivalTime }}
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="arrival.latestDelay !== null">
          <div class="flex no-wrap">
            <div class="column q-pr-md">
              <div class="text-caption text-grey-8" style="line-height: 1">
                Försening
              </div>
              <div
                class="text-body2 text-weight-bold q-mt-none q-mb-xs"
                :style="delayColorStyle"
              >
                {{ arrival.latestDelay }}
              </div>
            </div>

            <div class="column">
              <div class="text-caption text-grey-8" style="line-height: 1">
                Förseningsplats
              </div>
              <div class="text-body2 text-weight-bold q-mt-none q-mb-xs">
                {{ arrival.latestDelayPlace }}
              </div>
            </div>
          </div>
        </div>
      </q-card-section>

      <q-card-section class="col-auto q-pa-none">
        <div class="flex fit items-center">
          <q-btn color="grey-7" round flat icon="mdi-chevron-right" />
        </div>
      </q-card-section>
    </q-card-section>
  </q-card>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import { format } from 'date-fns'
import { useRouter } from 'vue-router'
import { Arrival } from '@/types/arrival'

export default defineComponent({
  name: 'ArrivalCard',

  props: {
    arrival: {
      type: Object as PropType<Arrival>,
      required: true,
    },
  },

  setup(props) {
    const router = useRouter()

    const arrivalTime = format(new Date(props.arrival.arrival), 'HH:mm')

    function onOpenTrain() {
      router.push(`/register/train/${props.arrival.uuid}`)
    }

    const delayColorStyle = computed(() => {
      if (
        !props.arrival ||
        props.arrival.latestDelay === null ||
        props.arrival.latestDelay === 0
      )
        return {
          color: 'black',
        }
      return {
        color: props.arrival?.latestDelay < 0 ? 'green' : 'red',
      }
    })

    return {
      status,
      arrivalTime,
      onOpenTrain,
      delayColorStyle,
    }
  },
})
</script>
