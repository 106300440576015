<template>
  <q-card>
    <q-card-section class="row items-center">
      <q-icon size="xs" name="mdi-alert-circle-outline" color="negative" />
      <span class="q-ml-sm text-subtitle1 text-bold">{{ error.name }}</span>
    </q-card-section>

    <q-card-section class="row text-grey-8">
      {{ error.description }}
    </q-card-section>

    <q-card-actions align="right">
      <q-btn flat label="Ok" color="primary" @click="showErrorModal = false" />
    </q-card-actions>
  </q-card>
</template>

<script lang="ts">
import { useError } from '@/compositions/useError'
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    const { showErrorModal, error } = useError()
    return { showErrorModal, error }
  },
})
</script>
