<template>
  <q-dialog
    v-model="show"
    maximized
    transition-show="slide-up"
    transition-hide="slide-down"
  >
    <q-card class="bg-black text-white">
      <q-bar>
        <q-space />
        <q-btn dense flat icon="mdi-close" v-close-popup> </q-btn>
      </q-bar>
      <q-img :src="src" style="height: 100%; width: 100%" fit="contain" />
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'VehicleDeviationImageFullScreenDialog',

  emits: ['update:modelValue'],

  props: {
    src: String,
    modelValue: Boolean,
  },

  setup(props, { emit }) {
    const show = computed({
      get() {
        return props.modelValue
      },

      set(value: boolean) {
        emit('update:modelValue', value)
      },
    })

    return { show }
  },
})
</script>
